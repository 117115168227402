@import '../../style/style.sass'
    
.header
    margin: 10px 40px 0
    &__info
        display: flex
        justify-content: space-between
        margin-top: 10px
        color: $gray-color        
    &__logo
        &_img
            height: 50px
        &_text
            font-size: 48px
            font-weight: bold
            color: $main-color
            margin: 0
        &_link
            text-decoration: none
            &:hover, &:visited, &:link, &:active
                color: $gray-color
                text-decoration: none
    &__contacts
        text-align: right
        &_title
            margin-bottom: 0
            font-size: 19px
            line-height: 22px
            font-weight: 700
        &_text
            font-size: 16px
            line-height: 18px
            margin-bottom: 0
        .email
            color: $gray-color
            text-decoration: none
            &:hover
                font-weight: bold
                color: $gray-color
    &__menu
        height: 40px
        display: flex
        justify-content: space-between
        align-items: center
        margin: 10px 20px 0
        list-style-type: none
        padding-left: 0
        &_item
            font-size: 20px
            a
                display: contents
                padding: 0 30px
                font-size: 20px
                font-weight: bold
                &:link, &:visited
                    color: $gray-color
                    text-decoration: none
                &:hover, &.active
                    color: $main-color

@media (max-width: 1090px)
    .header
        &__menu_item a
            padding: 0 20px
        &__logo_text
            font-size: 36px

@media (max-width: 992px) // burger-menu
    .header
        &__logo_text
            font-size: 30px
        &__nav 
            display: block
            position: absolute
            height: 60%
            width: 100%
            background-color: #fff
            z-index: 90
            transition: all 0.4s ease
            left:-100%
            &.open-menu
                left: 0
        &__menu
            display: block
            &_item
                margin: 20px
                font-size: 14px
        &__menu-burger
            position: relative
            width: 40px
            height: 35px
            display: block
            margin: 10px
            z-index: 100
            cursor: pointer
            span, &:after, &:before
                height: 3px
                width: 100%
                position: absolute
                background: #515758
                margin: 0 auto
            span
                top: 16px
            &:after, &:before
                content: ''
            &:after
                bottom: 5px	
            &:before
                top: 5px
            &.open-menu
                span
                    opacity:0 
                    transition: 0.5s
                &:after
                    transform: rotate(-38deg)
                    bottom: 16px
                    transition: 0.4s
                &:before
                    transform: rotate(38deg)
                    top: 16px
                    transition: 0.4s

@media (max-width: 767px)
    .header
        margin: 10px 20px 0
        &__logo
            &_img
                height: 40px
            &_text
                font-size: 22px
        &__contacts
            &_text
                font-size: 14px

@media (max-width: 576px)
    .header
        margin: 10px 10px 0
        &__info
            display: block
        &__logo
            &_text
                font-size: 20px
                text-align: center
        &__contacts
            display: flex
            justify-content: space-between
            margin-top: 5px
            padding: 5px
            &_title
                display: none