@import '../../style/style.sass'
    
.footer
    display: flex
    width: 100%
    height: 50px
    justify-content: center
    align-items: center
    margin-top: 30px
    background-color: $gray-color
    color: white
    font-size: 16px
    font-weight: bold
    div
        cursor: pointer

@media (max-width: 767px)
    .footer
        height: 40px
        font-size: 14px

@media (max-width: 425px)
    .footer
        margin-top: 20px
        font-size: 13px