@import '../../style/style.sass'
    
.description
    margin: 20px 10%
    &__text, &__list
        font-size: 16px
        line-height: 18px
        text-align: justify
    &__title
        margin-top: 20px
        margin-bottom: 3px
        font-size: 18px
        line-height: 1.2
        font-weight: 700
    &__list
        padding-left: 30px
        line-height: 1.5
    &__link
        color: black
        text-decoration: none
        &:hover
            color: $main-color
            font-weight: bold            
        img
            height: 50px
            margin-top: 10px
    &__license
        height: 100px
        cursor: pointer
        margin-bottom: 10px
        &_large
            width: 100%
    &__container
        display: grid
        grid-template-columns: 200px 1fr
        column-gap: 15px
        &_photo
            height: 300px
            object-fit: contain
            margin-top: 10px
        &_text
            font-size: 14px
            line-height: 24px
            text-align: left
            padding-top: 50px

@media (max-width: 576px)
    .description
        margin: 20px 5%
        &__container
            &_text
                padding-top: 20px

@media (max-width: 425px)
    .description
        &__title
            font-size: 16px
        &__text, &__list
            font-size: 14px
        &__link
            display: flex
            flex-direction: column
            font-size: 14px
        &__container
            grid-template-columns: 100%
            grid-template-rows: auto-fit auto-fit
            row-gap: 20px
            &_photo
                height: 250px
                margin: 10px auto 0
            &_text
                padding-top: 0
